import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../../components/Layout'
import { RingBaseSelect } from '../../components/RingBaseSelect'
import { SEO } from '../../components/SEO'
import { Txt } from '../../components/Txt'
import { fullorderFormUrl } from '../../utils'

export const Head = () => (
    <SEO
        title="CAD-platinumのフルオーダーはお客様がデザイナーになれる、自由で特別感のある結婚指輪。"
        description="簡単な手書きのデザインをメールフォームで送るだけで、完成画像とお見積もりを最短即日～3日でご覧頂けます。おふたりがデザイナーの自由で特別感のあるフルオーダーで、理想のペアリングをお求めください。"
        page="fullorder"
    />
)

const FullorderPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: 'フルオーダー' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <HeroImageWrapper>
                        <StaticImage
                            src="../../images/fullorder/full-1.jpg"
                            alt="フルオーダーの結婚指輪"
                        />
                    </HeroImageWrapper>
                    <TopDescriptionWrapper>
                        <TopDescriptionTxt>
                            一生に一度のオーダーメイドは
                        </TopDescriptionTxt>
                        <TopDescriptionTxt size="l">
                            “おふたり”がデザイナー
                        </TopDescriptionTxt>
                    </TopDescriptionWrapper>
                    <StaticImage
                        src="../../images/fullorder/full-2.jpg"
                        alt=""
                    />
                    <DescriptionWrapper>
                        <DescriptionTxt>
                            簡単な手書きのデザインと
                        </DescriptionTxt>
                        <DescriptionTxt>
                            ご希望の内容をメールするだけで
                        </DescriptionTxt>
                    </DescriptionWrapper>
                    <StaticImage
                        src="../../images/fullorder/full-3.jpg"
                        alt=""
                    />
                    <DescriptionWrapper>
                        <DescriptionTxt size="s">
                            ご来店前でも簡単に
                        </DescriptionTxt>
                        <DescriptionTxt size="s">
                            リングの完成画像とお見積りを
                        </DescriptionTxt>
                        <DescriptionTxt size="s">
                            お気軽にご覧頂けます
                        </DescriptionTxt>
                        <CatchTxt>リングデータ画像製作・お見積り 0円</CatchTxt>
                    </DescriptionWrapper>
                    <StaticImage
                        src="../../images/fullorder/full-4.jpg"
                        alt=""
                    />
                    <NoticeTxt size="xs">
                        ※手書きデザインなしでもテキストで詳細をお送り頂ければ、画像製作・お見積り作成は無料でご利用頂けます。
                    </NoticeTxt>
                    <TitleImageWrapper>
                        <StaticImage
                            src="../../images/fullorder/full-5.jpg"
                            alt=""
                        />
                    </TitleImageWrapper>
                    <DescriptionWrapper>
                        <DescriptionTxt>ご試着・ご注文の流れ</DescriptionTxt>
                    </DescriptionWrapper>
                    <StepTxt size="s">
                        デザイン画、お見積りをご覧頂きご注文の場合は、事前に試作のサンプルをご試着頂けます。店舗にご来店またはご自宅にお届けで、実際の形状や雰囲気をご確認頂けます。
                    </StepTxt>
                    <StaticImage
                        src="../../images/fullorder/full-6.jpg"
                        alt=""
                    />
                    <StepTxt size="s">
                        細かい修正やアレンジが決まったら、いよいよプラチナ、ゴールド製の本番リングを製作。デザイン画の設計データを造型出力するため、ご注文からすぐに鋳造が可能。世界に1つ、おふたりがデザインの結婚指輪が2週間で完成。納品になります。
                    </StepTxt>
                    <StaticImage
                        src="../../images/fullorder/full-7.jpg"
                        alt=""
                    />
                    <DescriptionWrapper>
                        <DescriptionTxt size="l">
                            “おふたり”がデザイナー
                        </DescriptionTxt>
                        <DescriptionTxt size="l">
                            フルオーダーを始めよう！
                        </DescriptionTxt>
                        <LinkImageWrapper>
                            <a href={fullorderFormUrl}>
                                <StaticImage
                                    src="../../images/fullorder/full-8.jpg"
                                    alt=""
                                />
                            </a>
                        </LinkImageWrapper>
                    </DescriptionWrapper>
                    <CheckTxt>まずはオーダーデザインをチェック↓</CheckTxt>
                    <StaticImage
                        src="../../images/fullorder/full-9.jpg"
                        alt=""
                    />
                    <LinkImageWrapper>
                        <Link to="/fullorder/catalog/">
                            <StaticImage
                                src="../../images/fullorder/full-10.jpg"
                                alt=""
                            />
                        </Link>
                    </LinkImageWrapper>
                    <Divider />
                    <DescriptionWrapper>
                        <DescriptionTxt>
                            デザインのイメージが湧かない方は
                        </DescriptionTxt>
                        <DescriptionTxt>
                            既成デザインのカスタマイズがおすすめ
                        </DescriptionTxt>
                    </DescriptionWrapper>
                    <RingBaseSelect hideCatchTxt />
                </Grid>
            </Grid>
        </Layout>
    )
}

const HeroImageWrapper = styled('h1')({
    margin: 0,
})
const TopDescriptionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '56px 8px 24px',
})
const TopDescriptionTxt = styled(Txt)({
    marginBottom: '32px',
})
const DescriptionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px 8px',
})
const DescriptionTxt = styled(Txt)({
    marginBottom: '16px',
})
const CatchTxt = styled(Txt)({
    marginBottom: '16px',
    color: ' #1a237e',
})
const NoticeTxt = styled(Txt)({
    display: 'block',
    padding: '8px',
    marginBottom: '16px',
})
const CheckTxt = styled(Txt)({
    display: 'block',
    padding: '8px',
})
const TitleImageWrapper = styled('div')({
    margin: '10px 0',
})
const StepTxt = styled(Txt)({
    display: 'block',
    padding: '12px',
})
const LinkImageWrapper = styled('div')({
    margin: '20px 0',
})
const Divider = styled('hr')({
    margin: '28px 0',
    borderStyle: 'none',
    borderTop: '1px solid #ccc',
})

export default FullorderPage
